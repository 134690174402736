import { contactUsPageContent } from '@/utils/contentData/contactUsPage';
export const CONTACT_FORM_URL_PATH = `${process.env.CONTACT_FORM_ACTION_PORTAL_ID}/${process.env.CONTACT_FORM_ACTION_FORM_GUID}`;
export const CONTEXT_PAGE_NAME = 'Contact us form';

export const {
  form: formContentData,
  social: socialContentData,
  button: buttonText
} = contactUsPageContent.formBox;
export const { title: formTitle, inputs } = formContentData;
export const { title: socialTitle, items: iconsList } = socialContentData;
