import Layout from '@/components/Layout';
import { ContactForm, ContactHeading } from '@/sections/Contact/components';
import { seoConfiguration } from '@/utils/seo-config';

const ContactPage = () => {
  const { title: titleSeo, image, description } = seoConfiguration.contact;

  return (
    <Layout image={image} title={titleSeo} description={description}>
      <ContactHeading />
      <ContactForm />
    </Layout>
  );
};

export default ContactPage;
