import PageHeader from '@/components/PageHeader';
import './ContactHeading.scss';
import { contactUsPageContent } from '@/utils/contentData/contactUsPage';

const { title, descriptionTop, descriptionBottom } =
  contactUsPageContent.header;

const ContactHeadingDescription = () => (
  <>
    {descriptionTop} <br /> {descriptionBottom}
  </>
);

const ContactHeading = () => (
  <div className="contact-heading-container w-100 flex-row justify-center">
    <div className="contact-heading__text w-100 flex-column">
      <PageHeader
        textPrimary={{
          text: title
        }}
        textSecondary={{
          text: <ContactHeadingDescription />
        }}
      />
    </div>
  </div>
);

export default ContactHeading;
