import { en } from '@/languages/en';
import {
  GithubVioletIcon as GithubIcon,
  LinkedinVioletIcon as LinkedinIcon,
  TwitterVioletIcon as TwitterIcon,
  YoutubeVioletIcon as YoutubeIcon
} from '@/static/assets/icons';
import {
  GITHUB_URL,
  LINKEDIN_URL,
  TWITTER_URL,
  YOUTUBE_URL
} from '@/utils/externalLinks';

const language = en;

export const contactUsPageContent = {
  header: {
    title: language.contact.title,
    descriptionTop: language.contact.descriptionTop,
    descriptionBottom: language.contact.descriptionBottom
  },
  formBox: {
    button: language.contact.contactForm.form.button,
    form: {
      title: language.contact.contactForm.form.title,
      inputs: {
        firstName: language.contact.contactForm.form.inputs.firstName,
        lastName: language.contact.contactForm.form.inputs.lastName,
        email: language.contact.contactForm.form.inputs.email,
        message: language.contact.contactForm.form.inputs.message
      }
    },
    social: {
      title: language.contact.contactForm.icons.title,
      items: [
        {
          id: 'linkedin-1',
          url: LINKEDIN_URL,
          icon: LinkedinIcon
        },
        {
          id: 'twitter-1',
          url: TWITTER_URL,
          icon: TwitterIcon
        },
        {
          id: 'github-1',
          url: GITHUB_URL,
          icon: GithubIcon
        },
        {
          id: 'youtube-contact-1',
          url: YOUTUBE_URL,
          icon: YoutubeIcon
        }
      ]
    }
  }
};
