import { Button } from '@/components/Button';
import { CONTEXT_PAGE_NAME } from '@/components/Footer/utils';
import { Input } from '@/components/Input';
import { Typography } from '@/components/Typography';
import useSubmitRequest from '@/hooks/useSubmitRequest';
import {
  CONTACT_FORM_URL_PATH,
  inputs,
  buttonText,
  socialTitle,
  iconsList
} from '@/sections/Contact/components/ContactForm/utils';
import './ContactForm.scss';

const ContactForm = () => {
  const {
    response,
    isLoading,
    handleChange,
    handleOnSubmit,
    fieldValues,
    errorFieldValues
  } = useSubmitRequest(CONTACT_FORM_URL_PATH, CONTEXT_PAGE_NAME, 'contact_us');
  const { firstName, lastName, email, message } = fieldValues;
  const { emailError, messageError } = errorFieldValues;

  const renderForm = () => (
    <form
      className="contact-form-wrapper col-lg-8 col-sm-12 align-center"
      onSubmit={handleOnSubmit}
    >
      <div className="contact-form-heading__text w-100 flex-row align-center">
        <Typography component="h2" typographyKind="header-secondary-large-bold">
          {socialTitle}
        </Typography>
      </div>
      <div className="contact-form__fields">
        <div className="contact-field-item">
          <Input
            name="firstName"
            label={inputs.firstName}
            additionalClass={'contact-field__input'}
            onChangeFunc={handleChange}
            value={firstName}
            contact
          />
        </div>
        <div className="contact-field-item">
          <Input
            name="lastName"
            label={inputs.lastName}
            additionalClass={'contact-field__input'}
            onChangeFunc={handleChange}
            value={lastName}
            contact
          />
        </div>
        <div className="contact-field-item">
          <Input
            name="email"
            label={`${inputs.email}*`}
            additionalClass={`contact-field__input ${
              emailError ? 'error' : ''
            }`}
            onChangeFunc={handleChange}
            value={email}
            error={emailError}
            contact
          />
        </div>
        <div className="contact-field-item">
          <Input
            name="message"
            label={`${inputs.message}*`}
            additionalClass={`contact-field__textarea ${
              messageError ? 'error' : ''
            }`}
            onChangeFunc={handleChange}
            value={message}
            error={messageError}
            textarea
            contact
          />
        </div>
      </div>
      <div className="contact-form__button flex align-center">
        <Button
          fontSize={20}
          padding={[4, 20]}
          textColor="default"
          color="primary"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? 'SENDING...' : buttonText}
        </Button>
      </div>
    </form>
  );

  const renderContactFormSuccessResponse = () => (
    <div className="contact-form-success-heading">
      <Typography
        component="p"
        typographyKind="text-primary-extra-large-medium"
        margin={[8, 0]}
      >
        {
          "Thank you for reaching out! We'll get back to you as soon as possible."
        }
      </Typography>
    </div>
  );

  const renderSocialIconsBox = () => (
    <>
      <Typography component="h3" typographyKind="header-secondary-medium">
        {socialTitle}
      </Typography>
      <div className="contact-form__icons-wrapper flex-row align-center justify-space-between">
        {iconsList.map(({ id, url, icon: Icon }) => (
          <a
            key={id}
            href={url}
            target={'_blank'}
            rel="noreferrer"
            className="contact-form__icon-box"
          >
            <Icon />
          </a>
        ))}
      </div>
    </>
  );

  return (
    <div>
      <div className="contact-form-container row justify-center">
        {response ? (
          <>
            {renderContactFormSuccessResponse()}
            <div className="contact-form__icons col-sm-12 flex-column justify-center align-center">
              {renderSocialIconsBox()}
            </div>
          </>
        ) : (
          <>
            {renderForm()}
            <div className="contact-form__icons col-lg-4 col-sm-12 flex-column justify-center align-center">
              {renderSocialIconsBox()}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
